@font-face {
  font-family: 'HarmonyOS_Sans_Regular_Italic';
  src: url('./assets/font/HarmonyOS_Sans_Regular_Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HarmonyOS_Sans_Black';
  src: url('./assets/font/HarmonyOS_Sans_Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HarmonyOS_Sans_Bold';
  src: url('./assets/font/HarmonyOS_Sans_Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HarmonyOS_Sans_Thin';
  src: url('./assets/font/HarmonyOS_Sans_Thin.ttf' )format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HarmonyOS_Sans_Medium';
  src: url('./assets/font/HarmonyOS_Sans_Medium.ttf' )format('truetype');
  font-weight: normal;
  font-style: normal;
}